@charset "UTF-8";


$black: #333;
$color_theme: #dc1818;

/*
normalise
*/
@import "vendors/animate";
@import "componement/base";
@import "componement/mixins";

@import "componement/over_materialize";
@import "componement/icons.scss";
@import "componement/menu_icon.scss";
@import "componement/menu";
@import "vendors/grid";
@import "vendors/jquery_fancybox";
//@import "vendors/slick-theme";
//@import "vendors/slick";
@import "vendors/jssocials";
@import "vendors/jssocials-theme-flat";


$typo1 : 'bw_gradualmedium', sans-serif;
$typo2 : 'bw_gradualregular', sans-serif;
/*
font-family: 'Roboto', sans-serif;
font-family: 'bw_gradualregular';
*/
/*
###########################
GO
########################### 
*/

@import "componement/galery";



.luminosity:before{    
	mix-blend-mode: hard-light;
}
      
body,html{
	    min-height: 100vh;
		color: #333;
		font-family: $typo2;
		font-weight: 400 !important;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-font-smoothing: antialiased;
		-ms-font-smoothing: antialiased;
		-o-font-smoothing: antialiased;
		letter-spacing: 0px;
}
body main{
	
	@include transition(all .3s ease);
	
}
html,body {

		
		
	font-size: 15px;
	line-height: 30px;
}

b,strong,small {
	font-family:$typo1;
}


figure{
	outline: 0;
	line-height: 0;
	margin: 0;
	line-height: 0;
}	
section {

	width: 100%;
	overflow: hidden;
}
.hero {
	height: 100vh;
}
.hero .valign-wrapper,
.hero .container {
	height: 100%;
}
.hero2 {
	height: 450px;

}
.bgblu:before {
	background-color: rgba(#020633,.2);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	display: block;
}
.hero small,.hero h2 {
	color: #FFF;
}
.hero h2 {
		font-size: 3rem;
}
h1,h2,h3,h4,h5,h6,#tooltip-bottom {
	font-family: $typo1;
	    /*font-weight: 400 !important;*/
	    text-rendering: auto;
	    -webkit-font-smoothing: antialiased;
	    -moz-font-smoothing: antialiased;
	    -ms-font-smoothing: antialiased;
	    -o-font-smoothing: antialiased;
	    letter-spacing: 0px;
		    margin-top: 5px;
			
		
}
.btn {
	background-color: $color_theme;
}

#tooltip-bottom {
   background: $color_theme;
   position: absolute;

   border: 1px solid transparent;
   padding-bottom: 1px;
   height: auto;
   border-radius: 0!important;
   box-shadow: none;

   color: #FFF;
   padding-top: 1px;
   font-size: 1.2rem;
   line-height: 3rem;
   text-transform: none;
   padding-left: 2rem;
   padding-right: 2rem;
   position: absolute;
       bottom: 0;
       right: 0;
       left: auto;
}
#tooltip-bottom:before {
   content:"";
   position: absolute;
   width: 0;
   height: 0;
   border-right: 20px solid transparent;
   border-bottom: 20px solid $color_theme;
   border-left: 0px solid transparent;
   margin: 0;
   margin-top: -20px;
}
h1,h2,h3,h4,h5,h6  {
	color:  rgba(65,72,74,1);
}

.or {
	color:  $color_theme;
}

.padd1 {
	padding: 10%;
}

h3 {
	font-size: 4vw;
	line-height: 5vw;
	
}
.list1 li a {
	color: #41484A;
	line-height: 2.5rem;
	font-size: 1.4rem;
}
.bgor {
	background-color: $color_theme;
	color: #FFF;
}
.cntr {
	text-align: center;
}
.bgor h1,
.bgor h2,
.bgor h3,
.bgor h4,
.bgor h5,
.bgor h6,
.bgor a {
	color: #FFF;
}
.bgor a {
	    font-size: 75%;
}
#newshome .newshead2 a {
	margin-top: 95px;
	position: relative;
	display: block;
	text-align: right;
	color: #8E9798;
}
.newsitem {
	width: 34vw;
	float: left;
	display: block;
}
.newsitem .caption {
	background-color: #8E9798;
	height: 206px;
	display: block;
}
.slick-initialized .slick-slide,
.newsitem {
    display: block;
    position: relative;
    width: 36vw;
	padding: 20px;
    overflow: hidden;
}

.slick-next,
.slick-prev,
.slick-next:focus,
.slick-prev:focus,
.slick-next:hover,
.slick-prev:hover,
 {
	background-color: $color_theme;
	width: 50px;
	    height: 50px;
	    border-radius: 50px;
		z-index: 10;
}
.slick-next:before,.slick-prev:before {
	font-family: 'Linearicons';
	    speak: none;
	    font-style: normal;
	    font-weight: 400;
	    font-variant: normal;
	    text-transform: none;
	    line-height: 1;
	    -webkit-font-smoothing: antialiased;
	    -moz-osx-font-smoothing: grayscale;
		
}
.slick-next,
.slick-prev,
.slick-next:focus,
.slick-prev:focus,
.slick-next:hover,
.slick-prev:hover {
		display: block;
		opacity: 1;
}

.slick-next {
	right: 7.5vw;
}
.slick-prev {
	left: 7.5vw;
}
.slick-next:before {
           content: "\e944";
    
}

.slick-prev:before {
    
	content: "\e943";
}

.newsitem h3 {
	color: #41484A;
}
.newsitem small {
	margin-top: 40px;
	display: inline-block;
	color: #8E9798;
}
.newsitem a {
	color: $color_theme;
}
.newshome,
#services {
	padding-top: 80px;
	padding-bottom: 80px;
}


.parts {
	max-width: 450px;
}
.slider {
    position: relative;
        height: 480px;
    width: 100%;
}
.newshome {
	padding-bottom: 0;
}
.infc {
	padding-bottom: 80px;
}
.bgor a {
    font-size: 1rem;
}
.ccr .valign-wrapper {
	height: 50px;
}
.btmright {
	color: #41484A;
	padding-top: 20px;
}


footer {
	background-color: #41484A;
	padding-bottom: 80px;
	   padding-top: 80px;
	   color: #FFF;
	
}
footer .btn-floating {
	background-color: #8E9798;
	box-shadow: none;
	margin-right: 5px;
	margin-left: 5px;
}
footer .btn-floating:hover {
	background-color: $color_theme;
	box-shadow: none;
	margin-right: 5px;
	margin-left: 5px;
	opacity: 1;
}
footer i:before {
	    line-height: inherit!important;
}
footer .footerend {
	color: #8E9798;
	font-size: 75%;
}
.btfullwidth {
	margin: 1.46rem 0 1.168rem;
	    color: #FFF;
	    display: block;
	    position: relative;
		line-height: 2.5rem;
		font-size: 1.4rem;
	
}
.btfullwidth i {
	text-align: right;
	display: block;
	float: right;
}
.toright {
	text-align: right;
}
.footerleft {
	    line-height: 2.6rem;
}
.infc .container {
	padding-top: 80px;
}
nav ul a:hover,
nav ul a.active {


}

nav ul a.active {

}
.navtop li a,
nav ul a {
	line-height: 44px;
	font-family: $typo1;
}
.navtop ul {
	    margin-top: 10px;
}
.bloc {
	position: relative;
	    display: block;
	    width: 100%;
}
.ccr .bloc a {
	float: right;
	display: block;
	position: relative;
}
.container {
    margin: 0 auto;
    width: 80%;
}
html {
    font-size: 1rem;
    line-height: 1.6rem;
}
.padd2 {
	padding-bottom: 80px;
	padding-top: 80px;
}
.container2 {
	width: 75%;
}
.container3 {
	width: 85%;
}
.container4 {
	width: 65%;
}
.servicesbloc li a {

	color: #FFF;
	
	
}
.servicesbloc .valign-wrapper {
	height: 400px;
	padding: 0;
	border: 10px solid #FFF;
}
.servicesbloc li h3,
.servicesbloc li a {
	color: #FFF;
}


.servicesbloc > .valign-wrapper > div,
.servicesbloc a.valign-wrapper > div {
	display: block;
	    position: relative;
	    width: 100%;
		padding: 10%;
}
.servicesbloc a.valign-wrapper {
	background-color: $color_theme;
	color: #FFF;
}

.input-field {
    position: relative;
    margin-top: 0;
	margin-bottom: 0px;

}
.paddtop {
	margin-top: 80px;
}
.fullwidth {
	width: 100%;
}
.gridchiffres li {
	float: left;
	position: relative;
	display: block;
}
.gridchiffres small,
.resultchiffre small {
	font-family:  $typo1;
}
.gridchiffres p {
	padding: 0;
	    margin: 0;
}
.resultchiffre p,
.resultchiffre h3,
.resultchiffre h2 {
	 margin: 0;
}
h2 {
    font-size: 3rem;
}
form p.radio {
    margin-bottom: 0;
    margin: 0;
}

.bigradio {
	
}
.bigradio [type=radio]:checked+label,
.bigradio [type=radio]:not(:checked)+label,
.bigradio [type=radio]:checked+label:before, 
.bigradio [type=radio]:not(:checked)+label:before {
    
    height: 14vw;
}
.bigradio [type=radio]:checked+label,
.bigradio [type=radio]:not(:checked)+label, {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	    display: inline-grid;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 10px;
	line-height: 1rem;
	text-align: center;
}

.select-dropdown li.disabled, .select-dropdown li.disabled>span, .select-dropdown li.optgroup {
   display: none;
}

.centr {
	text-align: center;
}
.response small {
	font-family:  $typo2;
}
.response {
	display: block;
	padding-top: 20px;
}
button[type=submit]{
    border: 1px solid transparent;
    padding-bottom: 1px;
    height: auto;
    border-radius: 0!important;
    box-shadow: none;
	background-color: $color_theme;
    color: #FFF;
}
.error {
	color: #CC2B2B;
	font-family:  $typo1;
	-webkit-font-smoothing: antialiased;
	border-color: #CC2B2B!important;
}
input:not([type]), input[type=date], input[type=datetime-local], input[type=datetime], input[type=email], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], textarea.materialize-textarea,
.select-wrapper input.select-dropdown {
    margin: 0 0 10px;
}

.select-wrapper span.caret {
    font-size: 10px;
    line-height: 10px;
    display: block;
    line-height: 20px!important;
    padding-top: 0;
        margin-top: 21px;
		    margin-right: 17px;
}

input:not([type]).valid, input:not([type]):focus.valid, input[type=date].valid, input[type=date]:focus.valid, input[type=datetime-local].valid, input[type=datetime-local]:focus.valid, input[type=datetime].valid, input[type=datetime]:focus.valid, input[type=email].valid, input[type=email]:focus.valid, input[type=number].valid, input[type=number]:focus.valid, input[type=password].valid, input[type=password]:focus.valid, input[type=search].valid, input[type=search]:focus.valid, input[type=tel].valid, input[type=tel]:focus.valid, input[type=text].valid, input[type=text]:focus.valid, input[type=time].valid, input[type=time]:focus.valid, input[type=url].valid, input[type=url]:focus.valid, textarea.materialize-textarea.valid, textarea.materialize-textarea:focus.valid {
    border-bottom: 1px solid $color_theme;
    box-shadow: 0 1px 0 0 $color_theme;
}
.imgtop {
	height: 400px;
	background-color: #8E9798;
}
.articleitem h3,
.articleitem a {
	color: #41484A;
}

.articleimg {
	height: 400px;
	width: 100%;
}
/*.select-wrapper .error {
	position: absolute;
}*/
.actulist .grid {
	margin-left: -3%;
}
.actulist [class*=col-] {
    padding-left: 3%;
    margin-bottom: 3%;
}
.articleimg2 {
	height: 300px;
	width: 100%;
	background-color: #8E9798;
}

textarea {

    border: 1px solid $color_theme;
    padding: 20px;
    box-sizing: border-box;
	height: 300px;
	font-family:  $typo1;
		-webkit-font-smoothing: antialiased;
}
.listicons li {
	    line-height: 35px;
}
.listicons li i {
	font-size: 23px;
	    margin-right: 18px;
}
.hero {
	    min-height: 450px;
}
.gridtaux h2 {
	margin-bottom: 0;
}
.gridtaux p {
	margin-top: 0;
}

.slidercontainer,
.slider,
.slick-list.draggable {
	overflow: visible;
}
.newsitem.slick-slide {
	opacity: .6;
}
.newsitem.slick-slide.slick-active {
	opacity: 1;
}
.ccr .col-1-2, 
.ccr h3 {
	margin-bottom: 0;
	margin-top: 0;
}
footer .btfullwidth i {

    line-height: 40px;
}
.servicesbloc a.valign-wrapper {
	margin-bottom: 0;
}

titrelinks h3 {
    font-size: 2rem;
    line-height: 2.6rem;
    margin-top: 0;
}
.list1 {
	    margin-top: 8px;
}
.list1 li a {

	line-height: 2.6rem;
}
footer .btfullwidth {
	margin-top: 9px;
}
.newsitem small {
    margin-top: 65px;
    display: inline-block;
    color: #8E9798;
}

nav ul a, .link:after {
    font-size: .75rem;
    display: block;
    padding: 0px;
    text-transform: uppercase;
	position: relative;
	    margin-left: 15px;
		
		
}
.navtop li a:hover, nav ul a:hover {
	background-color: transparent!important;
}
.hero .link:after,
.link:after,
nav ul a:after {
	content: "";
	    display: block;
	    width: 100%;
		left: -100%;
	    height: 2px;
	    position: absolute;
	    z-index: 0;
	    bottom: 0;
	    background: #fff;
	    transform: translateX(0);
		
		@include transition(all .3s ease);
}
.link:after {
	background:$color_theme ;
}

.link,nav ul a {
	overflow: hidden;
}
.link:hover::after, 
.link.active::after,
nav ul a:hover::after,
nav ul a.active::after {
	
	      transform: translateX(100%);
	   
}

nav ul a:hover::after,
nav ul a:hover::after {
	width: 100%;
	
}
#links li {
	position: relative;
	display: inline-block;
	margin-right: 15px;
}
a.link {
	color: #333;
	position: relative;
	display: inline-block;
	margin-right: 20px;
}

section a.link {

}
.hero a.link {
	color: #FFF;
}
.link:after {
	margin-left: 0;
}
.navtop li a, nav ul a,.link {
    line-height: 25px;
}

.container6 {
	width: 100%;
	box-sizing: border-box;
	padding-left: 5%;
	padding-right: 5%;
}
.bottom {
	position: absolute;
	    bottom: 5%;
		margin-bottom: 0;
}

.up_next {
	font-size: 40px;
	color: #FFF;
	position: absolute;
	bottom: 10%;
	right: 5%;
	
}

.color {
	color: $color_theme;
}
.cover {
	position: relative;
}
.cover.blu::after {
	content: "";
	background-color: rgba(65,72,74,.6);
	
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	    mix-blend-mode: overlay;

}
@media screen and (min-width: 1024px) {
.desync {
	width: 120%;
	margin-left: -20%;
}
}
.link {
	font-family: $typo1;
	text-transform: uppercase;
}


.bgwhite {
	padding: 10%;
	background-color: #FFF;
}
ul.linkshero {
	margin-top: 5px;
	margin-bottom: 0;
}
.hero h2 {
	margin-bottom: 0;
}
.linkshero li a {
	font-size: 14px;
	margin-bottom: 0;
	line-height: 20px;
}
footer {
	background-color: #000;
}
footer h3 {
	font-size: 1rem;
	line-height: 1.1rem;
	color: #FFF;
}
footer , footer small {
	color: #FFF;
}
.footerleft {
	line-height: 1rem;
	font-size: 12px;
	color: #666;
}
footer small {
	    color: #666;
}
footer a.link {
	color: #FFF;
	text-transform: none;
}
.citation h3 {
	font-size: 3rem;
	line-height: 4rem;
}
footer small {
	position: absolute;
	bottom: -20px;
	left: 0;
	    font-size: 26vw;
	opacity: .2;
	width: 100%;
	text-align: center;
	line-height: 22vw;
}
footer {
	display: block;
	overflow: hidden;
	position: relative;
}
footer .toright i {

	color: #FFF;
	font-size: 30px;
}
footer .toright a {
	    margin-left: 20px;
}
#gototop {
	position: fixed;
	font-size: 40px;
	    bottom: 10%;
	    right: 5%;
	z-index: 0;
}
.hero {
	z-index: 1;
}
#blockcitation {

	margin-top: 100px;
	
}
.infc .container, .padd2, footer {
    padding-top: 100px;
    padding-bottom: 100px;
}

html,body {
	cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="64" height="64"><circle cx="10" cy="10" r="10" style="fill: red;"/></svg>'), auto;
	
	
}
html,
body,
body *:hover {
	cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="64" height="64"><circle cx="10" cy="10" r="10" style="fill: red;"/></svg>'), auto;
}

.hero h2 {
    font-size: 4rem;
    /* max-width: 80%; */
    line-height: 4rem;
}
#gototop, .up_next {
    bottom: 5%;
}
footer {
	overflow: hidden;
	display: block;
	position: relative;
}
.imgblock {
	width: 100%;
}
/*
#footer .bgdeco {
	bottom: -400px;
	@include transition(all .3s ease-in-out);
	

			transition-delay: .3s;
}


#footer.scrolled .bgdeco {
	bottom: 0;
	transition-delay: .3s;
}*/
.logo {
	width: 100px;
	    padding-top: 20px;
}

.logoleft a {
    margin-top: 20px;
    display: block;
    margin-bottom: 20px;
    margin-left: 0;
    padding-left: 14%;
    max-width: 235px;
}
.imgheight2 {
	height: 300px;
}
.deco {
	    padding-left: 50px;
	overflow: hidden;
	position: relative;
}
.deco::after {
	content: "";
	position: absolute;
	display: block;
	width: 45px;
	    height: 2px;
	    top: 46%;
	left: -104px;
	transform: translateX(0px);
	background-color: #333;
	
	 @include transition(all .8s cubic-bezier(1, 0, 0, 1));
	 
	
}
.scrolled.visible .deco::after {
	transform:translateX(104px);
	
}
.black {
	background-color: #333;
	color: #FFF;
}
.black * {
	color: #FFF;
}
.alignright * {
	text-align: right;
}
.alignright .deco {
	padding-left: 0;
	padding-right: 100px;
}
.alignright .deco::after {
	left: auto;
	    right: -104px;
	transform: translateX(0px);
	transform-origin: top right;
	transition-delay: 1s;
}
.scrolled.visible .alignright .deco::after {
	transform:translateX(-104px);
	
	
}
.padd10 {
	    padding: 10%;
		padding-top: 0;
		padding-bottom: 0;
}
section {
	padding-top: 100px;
	padding-bottom: 100px;
}
.seeall {
	font-size: 20px;
	    line-height: 6vw;
	    vertical-align: middle;
	    color: #333;
	    padding-right: 100px;
		    float: right;
			
}
.seeall i {
	font-size: 6vw;
	line-height: 8vw;
	vertical-align: middle;
	color: #CCC;
	position: absolute;
	right: 0;
}
.bottom90 {
	transform: rotate(-90deg);
	position: absolute;
	bottom: 0;
	font-size: 3vw;
	transform-origin: top left;
}

.link.sousligne::after,
.black a.link.sousligne::after {
	transform: translateX(100%);
}
.black .link,
.black a.link {
    color: #FFF;
}
.link, .navtop li a, nav ul a {
    line-height: inherit;
}
p a,p a.link,
#footer h3 a.link {
	display: inline-block;
	margin: 0!important;
	padding: 0;
	line-height: inherit;
	
}
p a.link,
#footer h3 a.link {
	overflow: visible!important;
	transform: none;
	
	
	
}
p a.link::after,
#footer h3 a.link::after {
	transform: translateX(100%);
}
footer h3 {
    font-size: 1rem;
    line-height: 1.6rem;
}

.content a.link {
	text-transform: none;
}
.content a.link::after {
	background-color: #333;
}
.black .content a.link::after {
	background-color: #FFF;
}
.seeall {
	@include transition(all .8s cubic-bezier(1, 0, 0, 1));
}
.seeall:hover {
	transform: translateX(-20px);
	opacity: .5;
	
	
}
nav ul a {
		margin-left: 8px!important;
		margin-right: 8px!important;
}
.hero h2 {
    font-size: 3rem;
    line-height: 4rem;
}
.logo {
    width: 200px;
    padding-top: 20px;
}
.menuhamburger {
    z-index: 2000;
    top: 40px;
    right: 40px;
    left: auto;
    float: none;
    margin: 0;
    display: block;
    width: 50px;
	height: 50px;
}
.menuhamburger > div {
	margin: 5px;
	display: block;
	position: relative;
	    height: 40px;
}

.navtop,
.menuhamburger span {
	pointer-events: none;
}
.navtop .logo {
	pointer-events: visible;
}
.hero.hero2 {
	height: 450px;
}
/*
grid height
*/

.height1 {
	height: 250px;
}
.height2 {
	height: 520px;
}
.card-image.cover:before{
	content: "";
	background-color: rgba(#000,.2);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: block;
}
.card, .card-panel {
    margin: 10px;
	margin-left: 0;
	margin-right: 0;
}
.row .col {
	padding: 0;
    padding-left: 10px;
	padding-right: 10px;
}


.menuhamburger.open span, .menuhamburger.open span,
#nav-icon1.open span, #nav-icon3.open span, #nav-icon4.open span {
    background-color: #000;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.menuhamburger span {
	background-color: #FFF;
	@include transition(all .8s cubic-bezier(1, 0, 0, 1));
}
.menuhamburger.open span {
	background-color: #333;
}
#overlay_menu ul {
	padding: 30px;
}
#overlay_menu {
    overflow: hidden;
    display: block;
    position: fixed;
    top: 0;
    right: -300px;
	width: 300px;
	height: 100%;
	overflow: scroll;
	transform:translateX(0);
	z-index: 1000;
	@include transition(all .8s cubic-bezier(1, 0, 0, 1));
	background-color: #FFF;
	-webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
	box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
	
}
#overlay_menu.open {
	transform:translateX(-300px);
}
.up_next {
    font-size: 40px;
    right: 40px;
}
footer a {
	color: #FFF;
}
strong,b {
    font-weight: 800;
}
.bgor, .btn ,
.btn-large.active, .btn-large:focus, .btn-large:hover, .btn-large:visited, .btn.active, .btn:focus, .btn:visited{
    background-color: #CCC;
    font-size: 18px;
    margin: 0 auto;
    display: inline-block;
}
.logos {
	height: 60px;float: left;padding: 10px;width: auto;
}

.page-prev,.page-next {
	    position: relative;
}
.page-prev a,.page-next a {
	color: #333;
	padding-left: 50px;
	font-size: 21px;
	
}
.page-prev a i,.page-next a i {
	position: absolute;
	top: -5px;
	line-height: normal;
	font-size: 40px;
	left: 0;
	transform:translateX(0px);
	opacity: 1;
	@include transition(all .8s cubic-bezier(1, 0, 0, 1));
}
.page-prev a:hover i,.page-next a:hover i {
	transform:translateX(8px);
	opacity: .5;
}

.page-prev a:hover,.page-next a:hover {
	
	opacity: .5;
}
.txt {
	max-width: 850px;
	margin: 0 auto;
}
.card .card-content {
	text-align: left;
}
a .card .card-content {
	color: #333;
}

.btn, .btn-large,
.btn:hover, .btn-large:hover {
	    font-size: 18px;
}
button[type=submit] {
    background-color: #333;
}
input[type=datetime], input[type=email], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], textarea.materialize-textarea {
    border: 1px solid #333;
}


.troiscol {
	columns: 3 100px;
	column-width: 33,33%;
	  
}
.listlink li span {
	font-size: 14px;

	position: relative;


}

section.diffusion {
    padding-top: 30px;
    padding-bottom: 30px; 
}
.diffusion figure.circle {
	width: 50px;
	    height: 50px;
	    overflow: hidden;
		float: left;
}
.listlink li {
	display: block;
	clear: both;
}
.materialboxed img {
	max-width: none;
}

				

.copyright {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: rgba(255,255,255,.5);
	    font-size: 11px;
}
	
	.hero h2 {

		font-family: Roboto,serif;
		font-weight: 300;
		    font-size: 2vw;
		line-height: 3.5vw;
		text-align: center;
		display: block;
		    position: relative;
		    margin: 0 auto;
	}
	
	
	.rightmenu {
		position: absolute;
		top: 30px;
		right: 30px;
		
	}
	.rightmenu a {
		margin-bottom: 0;
		padding-bottom: 0;
	}
	.rightmenu li {
		    line-height: 23px;
	}
	#overlay_menu {
		transition: none;;
	}
	#overlay_menu {
	    display: block;
	    position: fixed;
	    top: 0;
	    right: 0;
	    width: 100vw;
	    min-height: 100vh;
		    transform: translateX(-100vw);
	}
	#overlay_menu.open {
	    display: block;
	    position: absolute;
	    top: 0;
	    right: 0;
	    width: 100vw;
	    min-height: 100vh;
		transform: translateX(0vw);
		    z-index: 10000;
		
			height: auto;
	}
	#overlay_menu ul {
		overflow: scroll;
	}
	#overlay_menu {
		background-size: cover;
		background-position: center center;
	}
	#overlay_menu .menuscroll {
	    padding: 30px;
	    position: absolute;
	    top: 30px;
	    right: 30px;
		display: block;
	
		    height: auto;
			overflow: hidden;
	}
	#overlay_menu ul li,#overlay_menu ul li a {
		color: #FFF;
	}
	
	.hero .link:after, .link:after, nav ul a:after,
	#overlay_menu ul li a:after {

	    background: #fff;
	}
.filtering li {
	    display: inline-block;
		margin-right: 10px;
		text-transform: uppercase;
}	
.masonry .item.hidethat {
	display: none;
}	
@media screen and(min-width: 601px) {
	nav, nav .nav-wrapper i, nav a.button-collapse, nav a.button-collapse i {
		    line-height: 30px;
	}
}
@media screen and(max-width: 601px) {
	.row .col.s4 {
	    width: 100%;
	}
	.hero.bgblu:before,
	.overlay.bgblu:before {
	    background-color: rgba(0,0,0,.6);
	}
	
	.hero.hero2.bgblu:before{
		background-color: rgba(0,0,0,.3);
	}
	
}

@import "componement/reveal";

@import "componement/responsive";

@media (max-width: 768px) {
		.col-1-2, .col-1-3, .col-1-4, .col-1-5, .col-1-8, .col-2-3, .col-2-5 {
		    width: 100%;
		}
}



