@charset "UTF-8";

.mason {
	margin-top: 30px;
	-moz-transition: all .5s ease-in-out;
	-webkit-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
	
	-moz-column-gap: 30px;
	-webkit-column-gap: 30px;
	column-gap: 30px;
	
	-moz-column-fill: initial;
	-webkit-column-fill: initial;
	column-fill: initial;
	
	-moz-column-count: 3;
	-webkit-column-count: 3;
	column-count: 3;
	
	vertical-align: baseline;
}
.mason.mason4col {
	-moz-column-count: 4;
	-webkit-column-count: 4;
	column-count: 4;
}

.mason .item {
	    margin-bottom: 30px;
		    display: block;/*display: inline-block;*/
		vertical-align: baseline;
		width: 100%;
}
@media screen and (max-width: 1024px) {
	.mason {
		-moz-column-count: 2!important;
		-webkit-column-count: 2!important;
		column-count: 2!important;
	}
}

@media screen and (max-width: 600px) {
	.mason {
		-moz-column-count: 1!important;
		-webkit-column-count: 1!important;
		column-count: 1!important;
	}
}

