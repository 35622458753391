@charset "UTF-8";


$color_icon_menu:#41484A;
$color_icon_menu_open : #41484A;



.menuhamburger {
  width: 30px;
      height: 30px;
  position: relative;
  margin: 0px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.menuhamburger span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: $color_icon_menu;
  border-radius: 0px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}


.menuhamburger span:nth-child(1) {
  top: 0px;
}

.menuhamburger span:nth-child(2) {
  top: 9px;
}

.menuhamburger span:nth-child(3) {
  top: 18px;
}

.menuhamburger.open span:nth-child(1) {
      top: 9px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.menuhamburger.open span:nth-child(2) {
  opacity: 0;
  left: -30px;
}

.menuhamburger.open span:nth-child(3) {
      top: 9px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}


.menuhamburger{
	float: left;
	margin-left: 20px;
	margin-top: 20px;
	z-index: 10000;
	
	    top: 18px;
	    left: 20px;
}

.menuhamburger {
    width: 30px;
    height: 30px;
    position: absolute;
    margin: 0 auto;

    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}


.menuhamburger span{
	background-color: $color_icon_menu;
	@include transition(all .3s ease);
}

.menuhamburger.open span,
.blackk .menuhamburger span{
	background-color: $color_icon_menu_open;
	@include transition(all .3s ease);
}

nav .button-collapse {
    float: right;
    z-index: 1;
	margin-right: 40px;
}
.logo {
	padding-left: 10px;
	    padding-top: 8px;
	    display: block;
	    width: 150px;
	    float: left;
}
.logo img {
	    max-width: 150px;
}
nav {
    background-color: transparent;
	box-shadow:none;
}
.logoleft a {
	margin-top: 20px;
	display: block;
	margin-bottom: 20px;
}
.logoleft a img {
	margin: 0 auto;
	width: 200px;
	    display: block;
}

.side-nav li {
    float: none;
    line-height: 48px;

}
nav ul a {
	color: #fff;
	    font-size: 14px;
}
nav {
	background-color: transparent;
	    box-shadow: none;
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    /* height: 100px; */
	    z-index: 10;
	    padding-top: 20px;
	    padding-bottom: 20px;
	    height: 100px;
}

.side-nav li {
    float: none;
    line-height: 32px;
}
.logoleft {
	    margin-top: 50px;
}
.side-nav li>a {
    font-size: 22px;
}
.side-nav li>a {
    padding: 0;
    margin-left: 14%;
	display: inline-block;
}
.side-nav li>a:hover {
	background-color: transparent;
}


/* letter anim */
.ml12 {
  font-weight: 200;
  font-size: 1.8em;
  text-transform: uppercase;
  letter-spacing: 0.5em;
}

.ml12 .letter {
  display: inline-block;
  line-height: 1em;
}




/* menu2 */
/*.side-nav.full {
	width: 100%;
}
.side-nav.full li {
    border: none;
}*/







