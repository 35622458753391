@charset "UTF-8";



@media screen and (min-width: 1024px) {
	.max50 {
		max-width: 60%;
		display: block;
		position: relative;
	}
	#agence small {
		margin-top: 15%;
		    display: block;
	}
	
	.hero .container .valign-wrapper>div{
		max-width: 60%;
		
	}
	.col-1-2.nomarginondesktop {
		padding-left: 0;
	}
	.onpinaille{
		    width: 52%;
	}
}

@media screen and (min-width: 780px) {
	
	.slick-initialized .slick-slide,
	.newsitem {
	    width: calc(80vw / 2);
	}
	
	
	.gridtaux{
		width: 60%;
	}
	.gridtaux2 {
		width: 80%;
	}
	.agence {
		width: 50%;
	}
	
	.list-container {
		margin-left: 100px;
	}
	.list1 {
		    margin-bottom: 60px;
	}
	
}

@media screen and (max-width: 780px) {
	.slick-initialized .slick-slide,
	.newsitem {
	    width: 40vw;
	}
	.actulist .articleimg2{
		height: 200px;
	}
	#tooltip-bottom {
	    font-size: 1rem;
	    line-height: 2rem;
	}
	#agence .col-1-2,
	#services .col-1-2,
	.newshome .col-1-2{
		width: 100%;
	}
	.list1 li a {
	    color: #41484A;
	    line-height: 2.5rem;
	    font-size: 1.2rem;
	}
	h3 {
	    font-size: 1.6rem;
	    line-height: 2.2rem;
	}
	.container4,
	.container3,
	.container,
	.container2 {
	    width: 90%;
	}
	#newshome .newshead2 a {
	    margin-top: 5px;
	}
	.onmobile100{
		width: 100%;
	}
	.select-wrapper input.select-dropdown,
	input:not([type]), input[type=date], input[type=datetime-local], input[type=datetime], input[type=email], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], textarea.materialize-textarea,
	[type=radio]:checked+label, [type=radio]:not(:checked)+label {
	    
	    font-size: .75rem;
	}
	.servicesbloc .valign-wrapper {
	    height: 300px;
	}
}

@media screen and (max-width: 600px) {
	.slick-initialized .slick-slide,
	.newsitem {
	    width: 100vw;
	}
	footer .col-1-2{
		width: 100%;
	}
	footer p.footerleft{
		text-align: left;
		line-height: 1.4rem;
	}
	footer .col-1-2.toright{
		float: none;
		text-align: left;
	}
	.actulist .col-1-2{
		width: 100%;
	}
	.articleimg,
	.articleimg2{
		height: 200px;
	}
	.valign-wrapper.articleimg{
		height: auto;
		display: block;
	}
	.articleitem{
		margin-bottom: 30px;

	}
	.hero h2 {
	    font-size: 5vw;
	    line-height: 6.5vw;
	    max-width: 80%;
	    text-align: left;
	    margin: 0;
	}
	
	.ccr .col-1-2:first-child {
		width: 100%;
		margin-top: 25px;
	}
	.ccr .valign-wrapper {
	    display: block;
	}
	
	
	.newsitem, .slick-initialized .slick-slide {

	    padding: 0px;
	
	}
	.slick-list{
		overflow: hidden!important;
	}
	footer .toright a {
	    margin-left: 0;
	}
	
	
	
}
@media screen and (min-width: 600px) and (max-width: 721px) {
	footer .col-1-2.toright b{
		display: none;
	}
}




