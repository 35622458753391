@charset "UTF-8";

$color_bg: #fff;
$delay : .5s;

.anm *,
.anm_vert *,
.scrolled .anm *,
.scrolled .anm_vert * {
opacity: 0;
}

.anm,
.anm_vert {
	    position: relative;
		    overflow: hidden;
			
}
.anm::after,
.anm_vert::after {
	
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	height: 100%;
	 transform-origin: top 0 left 0;
	 //ou scale
	background-color: $color_bg;
	content: "";
	
	 transition-delay: $delay;
	 @include transition(all $delay cubic-bezier(.3, 0, 0, 1));
	 transition: none;
}
.anm::after {
	
	
	transform:translateX(-103%) ;
}
.anm_vert::after {

	transform:translateY(-103%);
}




.scrolled .anm_vert::after {

	@include transition(all $delay cubic-bezier(.3, 0, 0, 1));
	transform:translateY(0%);
	opacity: 1!important;
}
.scrolled .anm::after {
	@include transition(all $delay cubic-bezier(.3, 0, 0, 1));
	transform:translateX(0%);
	opacity: 1!important;
}


.scrolled.visible .anm *,
.scrolled.visible .anm::after,
.scrolled.visible .anm_vert *,
.scrolled.visible .anm_vert::after {
	opacity: 1;
}
.scrolled.visible .anm::after {

	transform:translateX(103%);
}
.scrolled.visible .anm_vert::after {

	transform:translateY(103%);
}
.scrolled.visible .anm::after {
	transition-delay: $delay;
}
.scrolled.visible .anm_vert::after {
	transition-delay: .3s;
}
.anm,.anm_vert {
	margin-bottom: 3px;
    display: inline-block;
}

.whte .anm::after,
.whte .anm_vert::after{
	background-color: #333;
}