@charset "UTF-8";


@font-face {
    font-family: 'bw_gradualregular_italic';
    src: url('../fonts/bwgradual-regularitalic-webfont.woff2') format('woff2'),
         url('../fonts/bwgradual-regularitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'bw_gradualmedium_italic';
    src: url('../fonts/bwgradual-mediumitalic-webfont.woff2') format('woff2'),
         url('../fonts/bwgradual-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'bw_gradualregular';
    src: url('../fonts/bwgradual-regular-webfont.woff2') format('woff2'),
         url('../fonts/bwgradual-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'bw_gradualmedium';
    src: url('../fonts/bwgradual-medium-webfont.woff2') format('woff2'),
         url('../fonts/bwgradual-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
  font-family: "degiacinto";
  src:url("../fonts/degiacinto.eot");
  src:url("../fonts/degiacinto.eot?#iefix") format("embedded-opentype"),
    url("../fonts/degiacinto.woff") format("woff"),
    url("../fonts/degiacinto.ttf") format("truetype"),
    url("../fonts/degiacinto.svg#degiacinto") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "degiacinto" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "degiacinto" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
  content: "\61";
}
.icon-arrow-left:before {
  content: "\62";
}
.icon-arrow-right:before {
  content: "\63";
}
.icon-arrow-up:before {
  content: "\64";
}
.icon-alarm:before {
  content: "\65";
}
.icon-apartment:before {
  content: "\66";
}
.icon-camera:before {
  content: "\67";
}
.icon-camera-video:before {
  content: "\68";
}
.icon-cross:before {
  content: "\69";
}
.icon-file-empty:before {
  content: "\6a";
}
.icon-home:before {
  content: "\6b";
}
.icon-map-marker:before {
  content: "\6c";
}
.icon-paperclip:before {
  content: "\6d";
}
.icon-quotes-marks1:before {
  content: "\6e";
}
.icon-quotes-marks2:before {
  content: "\6f";
}
.icon-recompense-copie:before {
  content: "\70";
}
.icon-close:before {
  content: "\71";
}
.icon-plus2:before {
  content: "\72";
}
.icon-left:before {
  content: "\73";
}
.icon-right:before {
  content: "\74";
}
.icon-facebook:before {
  content: "\75";
}
.icon-linkedin:before {
  content: "\76";
}
.icon-linkedin-square:before {
  content: "\77";
}
.icon-linkedin-circled:before {
  content: "\78";
}
.icon-facebook-circled:before {
  content: "\79";
}